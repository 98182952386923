#skills{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: white;

}

.skills-container{
  padding-top: 20px;
}

.skill-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  margin: 0 200px;
}

.skill-box{
  padding: 1rem;
}

.skill-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.img{
  width: 90px;
  height: 90px;
  position: relative;
  border-radius: 45px;
  background-color: #159ab4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-title h4 {
  margin: 0.5rem;
}

.skill-icon {
  width: 50px;
}

@media only screen and (max-width: 800px) {
  .skill-container{
    margin: 0 10px;
  }
}